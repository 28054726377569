import React, { useState, useEffect } from 'react';
import './modal.scss';

const VideoModal = (props) => {
    return (
        <>
            <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="container">
                                <iframe width="100%" height="600" src="https://www.youtube.com/embed/OvqAF4ZIpYA">
                                </iframe>
                            </div>
                        </div>
                        <button type="button" class="btn btn-secondary close-btn" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VideoModal;