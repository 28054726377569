import React from 'react'
import './team.scss';
import 'font-awesome/css/font-awesome.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";

export default function Team() {

    return (
        <div class="container-lg team">
            <h2 class="title">Team</h2>
            <div class="row justify-content-md-center">
                <div class="col-xs-12 col-sm-4 team-content">
                    <div class="box">
                        <div class="team-member">
                            <img src={require('../images/founder.png')} />
                        </div>
                        <h2>Dionte</h2>
                        <h2 class="role">Founder, Full Stack Developer, Entrepreneur, and Blockchain developer. Bachelor of Science in CS, Software Engineer for 15+ years at top fortune 100 companies.</h2>
                        <a href="https://twitter.com/Finleyfan48">
                            <i className="fa fa-twitter"></i>
                        </a>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-4 team-content">
                    <div class="box">
                        <div class="team-member">
                            <img src={require('../images/artist.png')} />
                        </div>
                        <h2>Griconut</h2>
                        <h2 class="role">Artist and Designer</h2>
                        <a href="https://www.instagram.com/griconut/" target={"_blank"}>
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row justify-content-md-center">
                {/* <div class="col-xs-12 col-sm-4 team-content">
                    <div class="box">
                        <div class="team-member">
                            <img src={require('../images/Female-Pink.png')} />
                        </div>
                        <h2>Monika</h2>
                        <h2 class="role">Blockchain Developer</h2>
                        <a href="#">
                            <i className="fa fa-twitter"></i>
                        </a>
                    </div>
                </div> */}
                <div class="col-xs-12 col-sm-4 team-content">
                    <div class="box">
                        <div class="team-member">
                            <img src={require('../images/designer.png')} />
                        </div>
                        <h2>Khicha Studio</h2>
                        <h2 class="role">3D Engineer</h2>
                        <a href="https://www.instagram.com/khicha.studio/" target={"_blank"}>
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
