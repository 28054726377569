import React, { useEffect, useState, useRef } from "react";
import styles from "./metamask-auth.css";
import { Link } from "react-router-dom";
import Footer from '../Components/Footer';
import '../Views/Store/marketplace.scss';
import './mint.scss';
import myVideo from "../videos/Website2.mp4";
import capanionAbi from "./capanions.json";
import { ethers, BigNumber } from "ethers";

const mintAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3";

function isMobileDevice() {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

async function connect(setAccounts, setUserAddress) {
  if (!window.ethereum) {
    alert("Get MetaMask!");
    return;
  }

  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  setAccounts(accounts[0]);
  setUserAddress(accounts[0])
  localStorage.setItem('wallet', accounts[0])
}

async function checkIfWalletIsConnected({ setAccounts }) {
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    if (accounts.length > 0) {
      const account = accounts[0];
      setAccounts(account);
      return;
    }

    if (isMobileDevice()) {
      await connect(accounts);
    }
  }
}

async function checkIfLocalStorageWalletIsConnected({ setUserAddress, setAccounts }) {
  if (window.ethereum) {
    if (localStorage.getItem('wallet') !== null) {
      setAccounts(localStorage.getItem('wallet'));
      setUserAddress(localStorage.getItem('wallet'))
      return;
    }
  }
}


export default function Mint() {
  const [userAddress, setUserAddress] = useState("");
  const [mintAmount, setMintAmount] = useState(1);
  const [accounts, setAccounts] = useState([]);
  const alertRejectedRef = useRef();

  async function handleMint() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        mintAddress,
        capanionAbi.abi,
        signer
      );
      try {
        const res = await contract.mint(BigNumber.from(mintAmount));
        console.log("response: ", res);
      } catch (err) {
        console.log("error: ", err)
        alertRejectedRef.current.classList.remove("hide");

        setTimeout(() => {
          alertRejectedRef.current.classList.add("hide");
        }, 8000)
      }
    }
  }

  function handleSignOut() {
    localStorage.removeItem('wallet');
    setUserAddress("");
  }

  useEffect(() => {
    checkIfLocalStorageWalletIsConnected({ setUserAddress, setAccounts })
  }, []);

  return userAddress ? (
    <div className="marketplace">
      <header>
        <div className="logo"><Link to="/"><img src={require("../images/Capanions-logo-dark.png")} /></Link></div>

        <div class="connect-wallet">
          <button className="btn" onClick={handleSignOut}>Sign out</button>
        </div>
        <div className="wallet-address">
          Connected with <Address userAddress={userAddress} />
        </div>
      </header>
      <section id="video_section">
        <div className="inner-container">
          <div><h4 className="text-mint">Join our family! Be about of something special. (Max mint per wallet 3)</h4></div>
          <div className="mint">
            {accounts.length > 0 && (
              <div>
                <div className="input-spinners">
                  <div className="mint-count"><span>{mintAmount}</span></div>
                  <div className="btn-input-spinners">
                    <button className="btn btn-count" onClick={() => setMintAmount(mintAmount - 1)} disabled={mintAmount<=1}>-</button>
                    <button className="btn btn-count" onClick={() => setMintAmount(mintAmount + 1)} disabled={mintAmount>=3}>+</button>
                  </div>
                </div>
                <button className="btn btn-mint" type="mint" disabled>Mint Coming Soon</button>
              </div>
            )}
          </div>
          <div ref={alertRejectedRef} class="alert alert-danger mt-4 hide" role="alert" id="alert_mint_rejected">
            Transaction was rejected. Please try again.
          </div>
        </div>
        <video id="bgvid" autoplay='autoplay' muted loop playsInline>
          <source src={myVideo} type="video/mp4" />
        </video>
      </section>
      <Footer />
    </div>
  ) : (
    <div className="marketplace">
      <header>
        <div className="logo"><Link to="/"><img src={require("../images/Capanions-logo-dark.png")} /></Link></div>
        <div className="wallet-address">
          <div class="connect-wallet"><Connect setAccounts={setAccounts} setUserAddress={setUserAddress} /></div>
        </div>
      </header>
      <section id="video_section">
        <div className="inner-container">
          <div><h4 className="text-mint">Connect your wallet and join our family! Be apart of something special. (Max mint per wallet 3)</h4></div>
        </div>
        <video id="bgvid" autoplay='autoplay' muted loop playsInline>
          <source src={myVideo} type="video/mp4" />
        </video>
      </section>
      <Footer />
    </div>
  );
}


function Connect({ setAccounts, setUserAddress }) {
  if (isMobileDevice()) {
    const dappUrl = "metamask-auth.ilamanov.repl.co"; // TODO enter your dapp URL. For example: https://uniswap.exchange. (don't enter the "https://")
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    return (
      <a href={metamaskAppDeepLink}>
        <button className="btn">
          Connect Wallet
        </button>
      </a>
    );
  }

  return (
    <div>
      <button className="btn" onClick={() => connect(setAccounts, setUserAddress)}>
        Connect Wallet
      </button>
    </div>
  );
}


function Address({ userAddress }) {
  return (
    <span className={styles.address}>{userAddress.substring(0, 5)}…{userAddress.substring(userAddress.length - 4)}</span>
  );
}
