import React, { useState } from 'react';
import './contact.scss';

export default function Contact() {

    const [name, setName] = useState({ value: '' });
    const [emailAddress, setEmailAddress] = useState({ value: '' });
    const [subject, setSubject] = useState({ value: '' });
    const [companyName, setCompanyName] = useState({ value: '' });
    const [message, setMessage] = useState({ value: '' });

    return (
        <div className="container contact">
            <div className="title">Contact</div>
            <form name="contact" method="POST" data-netlify-recaptcha="true" data-netlify="true">
                <input type="hidden" name="form-name" value="contact"/>
                <div className="input-sections form-row">
                    <div className="col-md-4 form-group">
                        <div class="input-content ">
                            <label for="name">Name*</label>
                            <input name="name" type="text" required class="form-control" id="name" aria-describedby="name" onChange={(e) => setName({ value: e.target.value })} />
                        </div>
                    </div>
                    <div className="col-md-4 padding-adjust">
                        <div class="input-content ">
                            <label for="email">Email Address*</label>
                            <input type="email" name="email" required class="form-control" id="email" aria-describedby="email" onChange={(e) => setEmailAddress({ value: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="input-sections">
                    <div className="col-md-4">
                        <div class="input-content form-group">
                            <label for="name">Subject*</label>
                            <input name="subject" type="text" required class="form-control" id="subject" aria-describedby="subject" onChange={(e) => setSubject({ value: e.target.value })} />
                        </div>
                    </div>
                    <div className="col-md-4 padding-adjust">
                        <div class="input-content form-group">
                            <label for="cname">Company name (Optional)</label>
                            <input name="cname" type="text" class="form-control" id="companyName" aria-describedby="company" onChange={(e) => setCompanyName({ value: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="input-sections form-group">
                    <div className="col-8">
                        <div class="input-content form-group">
                            <label for="name">Message*</label>
                            <textarea name="message" type="text" required class="form-control" id="message" aria-describedby="message" onChange={(e) => setMessage({ value: e.target.value })} />
                        </div>
                    </div>
                </div>
                <div>
                    <p className='whitelist'>
                        Please do not contact about whitelist.
                        All of this information will be in our discord.
                    </p>
                </div>
                <div  class="g-recaptcha recaptcha" data-sitekey="6Lfx13YfAAAAAGaQrK4F_NgGdS00PlMlsj5TFH7e" data-netlify-recaptcha="true"></div>
                <div>
                    <button class="btn" type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}
