import Home from './Home/';
import Header from './Components/Header/';
import Footer from './Components/Footer/';

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
