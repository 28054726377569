import React from 'react'
import './about.scss';
import 'font-awesome/css/font-awesome.min.css';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";

export default function About() {
  return (
    <section className="container about">
      <div className="row justify-content-md-center about-section">
        <h2 className="title">About Capanions</h2>
        <p>Capanions is a company that supports NFT fanatics. Your NFT art, on a cap, that you can wear any where. We are a 3D NFT collection on the Ethereum or Solana Blockchain. The art concept comes from our founder, Dionte. Capanions wants to support our customers and 
          the entire Capanions community. A strong project isn't just by the founders, artists, engineers, etc. A strong community is made by the people involved.
          A few people that receive an invite to our Capanions list will have a say in what the cap will say and the primary color! Don't just buy in on a project. Have a say in a project. We
          will be selecting from our biggest supporters. A big surprise is coming to people who are in our Capanions list!   </p>
      </div>
    </section>
  );
}