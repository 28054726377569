/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
import React, { useState } from 'react';
import './home.scss';
import FAQ from '../FAQ';
import Team from '../Team';
import About from '../About';
import Contact from '../Contact';
import VideoModal from '../Components/VideoModal';

export default function Home() {
  const [showVideoModal, setShowVideoModal] = useState(false);

  return (
    <div className="Home">
      <div className="line-img">
        <img className="capanion-img" src={require("../images/Lines.png")} />
      </div>
      <main className='top-section'>
        <div class="container">
          <div class="row">
            <div class="col remove-col">
            </div>
            <div class="col right-section">
              <h2>Come Be A Part Of The <mark>Family</mark></h2>
              <p>Be a Capanion! We are a 3D NFT collection on the Ethereum or Solana Blockchain. Make your mark! Big surprise to Capanions list (Whitelist).</p>
              <hr />
              <div className="wallets">Supported by <img src={require("../images/Wallet-Icon.png")} /></div>
              <div>
                <a class="video-btn" data-bs-toggle="modal" data-bs-target="#videoModal"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-play-btn" viewBox="0 0 16 16">
                  <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                </svg>
                <span>Watch Intro</span>
                </a>
              </div>
              <br/>
              <div class="mint-btn">
              <button type="button" className="mint-mobile-btn btn btn-outline-secondary">Mint Coming Soon</button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <VideoModal />
      <div className="fixed-image">
        <img className="capanion-img" src={require("../images/nobackground-boy.png")} />
      </div>
      <About />
      <Team />
      <Contact />
      <FAQ />
    </div>
  );
}