import { Link } from "react-router-dom";
import Footer from '../../Components/Footer';
import './marketplace.scss';
import myVideo from "../../videos/Website2.mp4";

function Marketplace() {
  return (
    <div className="marketplace">
      <section id="video_section">
        <div className="inner-container">
          <div className="coming-soon">
            <div className="logo"><Link to="/"><img src={require("../../images/Capanions-logo-white.png")} /></Link></div>
            <span className="coming-soon-text">Store Coming Soon</span>
            <span className="text">The place for Web3 caps. Your favorite NFTs on a cap, beanie, etc. Keep a look out on our Twitter for updates.</span>
          </div>
        </div>
        <video id="bgvid" autoplay='autoplay' muted loop playsInline>
          <source src={myVideo} type="video/mp4" />
        </video>
      </section>
      <Footer />
    </div>
  );
}

export default Marketplace;
