import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Footer from '../Components/Footer';

import './terms.scss';
import 'font-awesome/css/font-awesome.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";

export default function Terms() {
    return (
        <>
            <div class="terms">
                <header>
                    <Link to="/"><img class="row" src={require("../images/Capanions-logo-dark.png")} /></Link>
                </header>
                <div class="container-lg">
                    <div class="container-3 w-container">
                        <div class="w-richtext">
                            <h2>Capanions LLC TERMS OF USE<br /></h2>
                            <p>
                                Capanions LLC is a amazing collection of nonfungible tokens running on the Ethereum blockchain. Users are responsible for the management and safety of their own private Ethereum wallets. Validating all transactions and contracts generated by this website. 
                                The primary purpose of this website is to provide information concerning Capanions LLC and provide access to users to purchase our
                                NFTs. Ethereum wallets are not operated by, maintained by, or affiliated with us, and we do not have custody or control over the contents of your wallet and have no ability to retrieve or transfer its contents. BECAUSE OUR SMART CONTRACT OPERATES ON THE ETHEREUM NETWORK THERE IS NO
                                ABILITY TO UNDO, REVERSE, OR RESTORE ANY TRANSACTIONS INVOLVING OUR NFTS.
                            </p>
                            <p><br /></p>
                            <p>
                                We may make changes to these Terms of Use at our discretion. Please check these Terms of Use periodically for changes. Any changes will apply on the date that they are made, and your continued access to or use after these Terms
                                of Use have been updated will constitute your binding acceptance of the updates. If you do not agree to any revised Terms of Use, you may not access or use this website.
                            </p>
                            <p><br /></p>
                            <ol role="list">
                                <li><strong>Terms of Sale.</strong><br /></li>
                            </ol>
                            <p>
                                Each Capanion Token is an NFT on the Ethereum network. When you purchase an NFT through our smart contract, we will grant you a non-exclusive license to use the artwork associated with that specific
                                NFT (the “Art”). Ownership of the NFT does not confer any ownership in the Art.
                            </p>
                            <p><br /></p>
                            <p>
                                If you elect to purchase a Capanion through our smart contract, any financial transactions that you engage in will be conducted solely through the Ethereum network. We will have no insight into or control over these payments or
                                transactions, nor do we have the ability to reverse any transactions. You agree that we will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you engage
                                or any other transactions that you conduct via the Ethereum network. Ethereum requires the payment of a transaction fee (a “Gas Fee”) for every transaction that occurs on the Ethereum network. This means that you will need to
                                pay a Gas Fee for each transaction.&nbsp;
                            </p>
                            <p><br /></p>
                            <p><strong>2. License Terms.</strong></p>
                            <p><strong>a. Non-Exclusive License.</strong><br /></p>
                            <p>
                                Subject to the restrictions in Section 2(B) below and your continued compliance with these Terms of Use, Capanions LLC grants you a worldwide, royalty-free, non-exclusive, non-transferable, non-sublicensable license to use,
                                copy, display the purchased Art, including for the purpose of creating derivative works based on the Art (“Adapted Art”); provided, however, any such Adapted Art must contain the original traits or features of the Art (including
                                color schemes) and may not use traits or features from other Capanion artwork (including their color schemes). Upon failure by you (or any authorized person) to comply with these Terms of Use, we shall be entitled to terminate
                                this license.<strong>&zwj;</strong>
                            </p>
                            <p>&zwj;</p>
                            <p><strong>b. Restricted Use.&nbsp;</strong></p>
                            <p>The following are prohibited with the express written permission of Capanions LLC:</p>
                            <p><br /></p>
                            <ol role="list">
                                <li><strong>No False Claims of Ownership.&nbsp;</strong><br /></li>
                            </ol>
                            <p>
                                Nothing in these Terms of Use constitutes or may be construed as permission to assert or imply that you are, or that your use of the Art is sponsored, endorsed, or granted official status by us. You may not falsely represent
                                that you are the creator or owner of any Art.
                            </p>
                            <p><br /></p>
                            <ol start="2" role="list">
                                <li><strong>No Use in Trademark or Logo.&nbsp;</strong><br /></li>
                            </ol>
                            <p>
                                Unless explicitly authorized by us, you may not use the Art (in whole or in part) or any Adapted Art as the distinctive or distinguishing feature of a trademark, design mark, trade name, business name, service mark, or logo.
                                Additionally, you shall not be entitled to register (in any jurisdiction) such content (in whole or in part) as a trademark or rely on any such registrations, prior use, and/or accrued goodwill to prevent any third party use of
                                the content or any similar content (including by us, our customers, or the copyright owner of such content).
                            </p>
                            <p><br /></p>
                            <ol start="3" role="list">
                                <li><strong>No On-Demand Use.&nbsp;</strong><br /></li>
                            </ol>
                            <p>
                                Unless explicitly authorized by us, you may not use the Art or Adapted Art in connection with “on-demand” products (e.g., products in which a licensed image is selected by a third party for customization of such product on a
                                made-to-order basis), including, without limitation, postcards, mugs, t-shirts, calendars, posters, commercial screensavers or wallpapers on mobile phones, or similar items (this includes the sale of products through custom
                                designed websites, as well as sites such as zazzle.com and cafepress.com).
                            </p>
                            <p><br /></p>
                            <ol start="3" role="list">
                                <li><strong>Your Obligations.&nbsp;</strong><br /></li>
                            </ol>
                            <p>
                                You are solely responsible for your own conduct while accessing or using this website, and for any consequences thereof. You agree to use the site only for purposes that are legal, proper and in accordance with these Terms of
                                Use and any applicable laws or regulations. By way of example, and not as a limitation, you may not, and may not allow any third party to: (i) send, upload, distribute or disseminate any unlawful, defamatory, harassing, abusive,
                                fraudulent, hateful, violent, obscene, or otherwise objectionable content; (ii) distribute viruses, worms, defects, trojan horses, corrupted files, hoaxes, or any other items of a destructive or deceptive nature; (iii)
                                impersonate another person; (iv) upload, post, transmit or otherwise make available through the website any content that infringes the intellectual property or proprietary rights of any party or otherwise violates the legal
                                rights of others; (v) engage in, promote, or encourage illegal activity (including, without limitation, money laundering); (vi) interfere with other users’ use of the website; (vii) use the website for any unauthorized
                                commercial purpose; (viii) modify, adapt, translate, or reverse engineer any portion of the website; (ix) remove any copyright, trademark or other proprietary rights notices contained in or on the website or any part of it; (x)
                                use any technology to collect information about the website’s for any unauthorized purpose; (xi) access or use the website for the purpose of creating a product or service that is competitive with any of our products or
                                services. If you engage in any of the activities prohibited by this Section 3, we may, at our sole and absolute discretion, without notice to you, and without limiting any of our other rights or remedies at law or in equity,
                                immediately suspend or terminate your user account.
                            </p>
                            <p><br /></p>
                            <ol start="4" role="list">
                                <li><strong>Disclaimers.&nbsp;</strong><br /></li>
                            </ol>
                            <p>
                                YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THIS WEBSITE IS AT YOUR SOLE RISK, AND THAT THIS WEBSITE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE
                                FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE SITE AND ANY PART OF IT (INCLUDING, WITHOUT LIMITATION, THIS WEBSITE, ANY SMART
                                CONTRACT, OR ANY EXTERNAL WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE
                                FOREGOING, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THIS WEBSITE WILL MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE SITE WILL BE
                                UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (III) USAGE DATA PROVIDED THROUGH THE SITE WILL BE ACCURATE, (III) THIS WEBSITE OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THIS WEBSITE ARE FREE OF
                                VIRUSES OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THIS WEBSITE WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR
                                ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
                            </p>
                            <p><br /></p>
                            <p>
                                YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR WILLFUL MISCONDUCT.
                            </p>
                            <p><br /></p>
                            <p>WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE ETHEREUM NETWORK NOR DO WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES REGARDING ANY SMART CONTRACTS.</p>
                            <p><br /></p>
                            <ol start="5" role="list">
                                <li><strong>Limitation of Liability.</strong><br /></li>
                            </ol>
                            <p>
                                YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY,
                                INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE
                                LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR
                                INABILITY TO ACCESS OR USE) ANY PORTION OF THE SITE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 12 MONTH
                                PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) $500. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY
                                SET FORTH HEREIN. WE WOULD NOT BE ABLE TO PROVIDE THE SITE TO YOU WITHOUT THESE LIMITATIONS.
                            </p>
                            <p><br /></p>
                            <ol start="6" role="list">
                                <li><strong>Assumption of Risk.&nbsp;</strong><br /></li>
                            </ol>
                            <p>You accept and acknowledge each of the following:<br /></p>
                            <ul role="list">
                                <li>
                                    You assume all risks associated with the purchase and sale of our NFTs, including any volatility associated with the price of our NFTs or Ethereum, as well as all risks associated with using an internet-based currency
                                    (issues with hardware, software, internet connection, and unauthorized access to your Ethereum wallet).<br />
                                </li>
                            </ul>
                            <ul role="list">
                                <li>
                                    You understand that ownership of our NFTs entitles you to license our Art. No information on our website is or may be considered to be advice or an invitation to enter into an agreement for any investment purpose. Nothing on
                                    this website is intended to be an offering of any securities in any jurisdiction nor does it constitute an offer or invitation to purchase shares (including in our company), securities, or other financial instruments.
                                    Because our project consists of licensing art, we have not registered with any securities regulators. It remains your sole responsibility to ensure that your purchase of our NFTs and license to use our artwork is in
                                    compliance with the laws and regulations of your jurisdiction.<br />
                                </li>
                            </ul>
                            <ul role="list">
                                <li>
                                    NFTs, cryptocurrencies and blockchain technology are relatively new and the regulatory landscape is unsettled. New regulations could negatively impact such technologies impacting the value for your licensed artwork and NFTs.
                                    You understand and accept all risks associated with any regulations that impact such value.&nbsp;<br />
                                </li>
                            </ul>
                            <ul role="list">
                                <li>You assume all responsibility for any adverse effects of disruptions or other issues impacting Ethereum or the Ethereum network.&nbsp;</li>
                            </ul>
                            <p><br /></p>
                            <ol start="7" role="list">
                                <li><strong>Indemnification.</strong><br /></li>
                            </ol>
                            <p>
                                To the fullest extent permitted by applicable law, you will indemnify, defend and hold harmless us, our employees, contractors, service providers, consultants, successor organizations, and each of their respective officers,
                                directors, agents, partners and employees (individually and collectively, the “Indemnified Parties”) from and against any losses, liabilities, claims, demands, damages, expenses or costs (“Claims”) arising out of or related to
                                (a) your Feedback; (b) your violation of these Terms of Use; (c) your violation, misappropriation or infringement of any rights of another (including intellectual property rights or privacy rights); (d) your misconduct in
                                connection with this website; (e) your violation of our non-exclusive license; or (f) your use of the Art or any Adapted Art. You agree to promptly notify Indemnified Parties of any third-party Claims, cooperate with Indemnified
                                Parties in defending such Claims and pay all fees, costs and expenses associated with defending such Claims (including, but not limited to, attorneys’ fees). You also agree that the Indemnified Parties will have control of the
                                defense or settlement, at the Indemnified Party’s sole option, of any third-party Claims.
                            </p>
                            <p><br /></p>
                            <ol start="8" role="list">
                                <li><strong>Dispute Resolution; Arbitration.</strong><br /></li>
                            </ol>
                            <p>
                                All disputes arising out of or in connection with these Terms of Use, including without limitation your access or use of this website, or to any products sold or distributed through this website, including our NFTs
                                (collectively, “Disputes”) will be referred to and finally resolved by confidential arbitration under the rules of the American Arbitration Association. The case will be adjudicated by a single arbitrator and will be
                                administered by the American Arbitration Association in accordance with its applicable rules. Each party will cover its own fees and costs associated with the arbitration proceedings. The place of arbitration will be Seattle,
                                Washington. The award of the arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Notwithstanding the foregoing, we may seek and
                                obtain injunctive relief in any jurisdiction in any court of competent jurisdiction.
                            </p>
                            <p><br /></p>
                            <p>
                                WITH RESPECT TO ANY DISPUTES: (I) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY; AND (II) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING BUT
                                NOT LIMITED TO CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.
                            </p>
                            <p><br /></p>
                            <ol start="9" role="list">
                                <li><strong>Minors.</strong><br /></li>
                            </ol>
                            <p>
                                Our website is not intended for children.&nbsp; You must be at least 18 years old to access this website or purchase our NFTs. If you are under 18 years old you are not permitted to use this website for any reason. By accessing
                                our website, you represent and warrant that you are at least 18 years of age.
                            </p>
                            <p><br /></p>
                            <ol start="10" role="list">
                                <li><strong>Severability.</strong><br /></li>
                            </ol>
                            <p>
                                If any provision or part of a provision of these Terms of Use is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and
                                enforceability of any remaining provisions.
                            </p>
                            <p>&nbsp;</p>
                            <ol start="11" role="list">
                                <li><strong>Feedback.&nbsp;</strong><br /></li>
                            </ol>
                            <p>
                                You may choose to submit comments, bug reports, ideas or other feedback about this website, including without limitation about how to improve this website (collectively, “Feedback”). By submitting any Feedback, you agree that we
                                are free to use such Feedback in any way we choose without additional compensation to you and you hereby grant us a perpetual, irrevocable, nonexclusive, worldwide license to incorporate and use the Feedback for any purpose.
                            </p>
                            <p><br /></p>
                            <ol start="12" role="list">
                                <li><strong>Miscellaneous.</strong><br /></li>
                            </ol>
                            <p>
                                Our failure to exercise or enforce any right or provision of these Terms of Use will not operate as a waiver of such right or provision. The section titles in these Terms of Use are for convenience only and have no legal or
                                contractual effect. Except as otherwise provided herein, these Terms of Use are intended solely for the benefit of the parties and are not intended to confer third-party beneficiary rights upon any other person or entity. You
                                may not assign or delegate any rights under these Terms of Use. We may freely assign or delegate our rights under these Terms of Use. You agree that communications and transactions between us may be conducted electronically. You
                                agree that these Terms of Use constitute the entire agreement between you and us and govern your use of our services, our website, and any products sold or distributed through this website, including our NFTs, superseding any
                                prior agreements between you and us on these subjects.&nbsp;
                            </p>
                            <p>
                                <br />
                                <br />
                            </p>
                            <p><em>* These terms are subject to change without notice.</em></p>
                            <p><br /></p>
                            <p><br /></p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
