import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import About from './About';
import Mint from './Mint';
import Terms from './Terms';
import Store from './Views/Store';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './fonts/Monda-Regular.ttf';
import './fonts/Monda-Bold.ttf';
import ScrollToTop from './Components/ScrollToTop/scrolltotop';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/about" element={<About />} />
      <Route path="/mint" element={<Mint />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/store" element={<Store />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
