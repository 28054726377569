import React from 'react';
import { Link } from "react-router-dom";
import './header.scss';
import 'font-awesome/css/font-awesome.min.css';

export default function Header() {
    function roadmapComingSoon() {
        alert('Coming Soon, please keep an eye out on our Twitter and Discord for updates!')
    }

    return (
        <header className="App-header">
            <nav class="navbar">
                <div className="links">
                    {/* <Link class="hide" to="/mint">Mint</Link>
                    <Link class="hide" to="/about">Terms Of Use</Link> */}
                    <div className="col-md-4 box">
                        <ul className="list-inline social-buttons">
                            <li className="list-inline-item">
                                <a href="https://twitter.com/Capanions_NFT">
                                    <i className="fa fa-twitter"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <a data-bs-toggle="tooltip" data-bs-placement="top" title="Coming Soon" to="#" href={require('../../whitepaper/Capanions.pdf')} target="_blank">Whitepaper</a>
                    <Link data-bs-toggle="tooltip" data-bs-placement="top" title="Coming Soon" to="/store">Store</Link>
                </div>
                <div class="logo"><img src={require("../../images/Capanions-logo-dark.png")} /></div>
                <button type="button" className="btn btn-outline-secondary hide">Mint Coming Soon</button>
            </nav>
        </header>
    )
}
